<template>
    <div class="prospus-auth-dashboard">
        <div class="prospus-auth-dashboard-wrap">
            <div class="container">
                <div class="prospus-auth-dashboard__header">
                    <img
                        src="../../../assets/kaamfu_logo.png"
                        alt="kaamfu logo"
                    />
                </div>

                <div class="prospus-auth-dashboard__content">
                    <p>
                        Kaamfu is currently <b>invite-only</b>; access is
                        granted through a direct invitation.
                    </p>
                    <!-- <img src="../../../assets/kaamfu_work_here.jpg" alt="work here"> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>
