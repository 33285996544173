<template>
    <div id="prospus-client-auth">
        <PermissionError v-if="show_permission_error_page"></PermissionError>
        <ExpiredPasswordLink
            v-else-if="showResetPassword & hasResetPasswordExpired"
        ></ExpiredPasswordLink>
        <template v-else>
            <AuthLayoutWrapper
                ><Auth
                    :show-sign-up="showSignUp"
                    :show-reset-pwd="showResetPassword"
                    :invitation-code="invitation_code"
                    @update:show-reset-pwd="showResetPassword = $event"
                    @sign-up="onShowSignUp"
                    @sign-in="onShowSignIn"
            /></AuthLayoutWrapper>
            <!-- <AuthDashBoard />
            <div id="prospus-chat-auth">
                <AuthPanel
                    :show-sign-up="showSignUp"
                    :show-reset-pwd="showResetPassword"
                    :invitation-code="invitation_code"
                    @sign-up="onShowSignUp"
                    @sign-in="onShowSignIn"
                >
                </AuthPanel>
            </div> -->
        </template>
    </div>
</template>

<script>
import AuthPanel from "./Modules/Auth/AuthPanel.vue";
import AuthDashBoard from "./Modules/Auth/AuthDashBoard.vue";
import PermissionError from "./Modules/Auth/PermissionError.vue";
import ExpiredPasswordLink from "./Modules/Auth/ExpiredPasswordLink.vue";
import Auth from "js/Auth/js/Pages/Auth/AuthIndex.vue";
import AuthLayoutWrapper from "js/Auth/js/Modules/Components/Auth/AuthLayoutWrapper.vue";

export default {
    components: {
        PermissionError,
        AuthPanel,
        AuthDashBoard,
        ExpiredPasswordLink,
        Auth,
        AuthLayoutWrapper,
    },
    data() {
        return {
            showSignUp: false,
            showResetPassword: false,
            show_permission_error_page: false,
            hasResetPasswordExpired: false,
        };
    },

    computed: {
        isAuthenticated() {
            let token = localStorage.getItem("kaamfu_user_token");

            return token && token.length > 0;
        },
        invitation_code() {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const invitationCode = urlParams.get("invitation-code");
            return invitationCode;
        },
    },

    mounted() {
        const params = new URL(location).searchParams;
        if (params.get("action") == "reset_password") {
            this.showResetPassword = true;

            this.hasResetPasswordExpired = Boolean(
                Number(params.get("token_expired")),
            );
        }
        this.show_permission_error_page = this.hasPath();

        if (this.invitation_code) {
            this.showSignUp = true;
        }
    },

    methods: {
        onShowSignIn() {
            this.showSignUp = false;
        },
        onShowSignUp() {
            this.showSignUp = true;
        },
        hasPath() {
            const parsedURL = new URL(location.href);
            return (
                parsedURL.pathname !== "/" && parsedURL.pathname.length === 9
            );
        },
    },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100..900;1,100..900&display=swap");
</style>
