<template>
    <div class="prospus-permission-card">
        <img src="/images/kaamfu_logo.png" width="200" />
        <p class="prospus-permission-message">
            You do not have permission to access this resource.
        </p>
        <v-btn color="primary" href="/">Back to Kaamfu</v-btn>
    </div>
</template>

<script>
export default {};
</script>
