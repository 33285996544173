<template>
    <div class="server-error">
        <div class="header bg-primary d-flex align-center px-6">
            <img
                src="/images/kaamfu-text-logo.png"
                alt="Server Image"
                width="110"
            />
        </div>
        <div class="content d-flex align-center justify-center">
            <div class="content_ui">
                <div class="d-flex align-start justify-start ga-4">
                    <KAvatar
                        image-src="/images/broken.svg"
                        name=""
                        bg-color=""
                        rounded="0"
                        size="x-large"
                    />
                    <div class="w-100">
                        <h2 class="text-priamry">Invalid Link</h2>
                        <v-divider class="my-3" />
                        <p class="pr-6">
                            The password link is invalid or has expired. Please
                            <span class="font-weight-bold"> request</span> a new
                            password reset link or try to
                            <span class="font-weight-bold">sign in again.</span>
                        </p>
                    </div>
                </div>
                <v-divider class="my-4" />
                <div class="d-flex justify-end">
                    <VBtn
                        variant="plain"
                        class="px-3"
                        :style="{ fontWeight: 400 }"
                        rounded
                        href="/"
                        >SIGN IN AGAIN</VBtn
                    >
                    <VBtn
                        variant="outlined"
                        rounded="xl"
                        class="px-6"
                        :style="{ fontWeight: 400, paddingTop: '2px' }"
                        @click="showForgotPwd = true"
                        >REQUEST NEW LINK</VBtn
                    >
                </div>
            </div>
        </div>
    </div>
    <Transition v-if="showForgotPwd" duration="550" name="nested">
        <ForgotPassword @close="showForgotPwd = false"></ForgotPassword>
    </Transition>
</template>

<script setup>
import { ref } from "vue";
import ForgotPassword from "../Components/Modal/ForgotPassword.vue";
import KAvatar from "js/Admin/js/Storybook/primitive/k-avatar/k-avatar.vue";

const showForgotPwd = ref(false);
</script>
<style lang="scss" scoped>
.server-error {
    .header {
        height: 70px;
    }
    .content {
        height: calc(100vh - 70px);
        .content_ui {
            width: 30rem;
        }
    }
}
</style>
